
import RcTableViewModel from "../../../../../shared/table/RcTableViewModel";

import ListViewUtils from "./ListViewUtils";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import InvoiceListFilter from '@/domain/model/invoice/InvoiceListFilter.js';
import InvoiceMap from '@/domain/model/invoice/InvoiceMap.js';
import RcTime from "../../../../../../domain/session/time/RcTime";

import RcResponseEvent from "../../../../../../domain/model/events/RcResponseEvent";

export default class ListViewModel extends RcTableViewModel {

  constructor(panel) {
    super(panel);
    this._companyId = ContentUtils.baseDecode(panel.$route.params.operatorId);
    this._userId = null;
    this._total = 0;
    this._error = null;
    this._itemList = [];
    this._pages = [];
    this.SU = StringUtils;
    this.CU = ContentUtils;
  }

  start() {
    super.start();
  }

  company() {
    return this.domain().companys().findById(this._companyId);
  }

  isAdmin() {
    return super.isAdmin();
  }

  withValue(valueData) {
    super.withValue(valueData, "invoice");
  }

  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
    this.log().info("Operator {0}", [JSON.stringify(buttonData, null, 2)]);
    if (!buttonData) {
      return;
    }
  }

  buildRowData(tableData) {
    var utils = new ListViewUtils(this, tableData);
    utils.start();
    return this;
  }

  createFilter() {
    var query = this.panel().$route.query;
    var time = "all";
    var month = query["month"] ? query["month"] : RcTime.time().now().month().previous().value() + ""
    if (!this.isAdmin() || !this.isAdminDisplay()) {
      month = "all";
    } else {
      if (StringUtils.isNotEmpty(month) &&
        month != "all" &&
        month != "thisMonth" &&
        month != "lastMonth" &&
        month != "last30Days" &&
        month != "last60Days") {
        let now = RcTime.time().now();
        let yearNumber = now.year().value();
        if (month < 0) {
          yearNumber = yearNumber - 1;
          month = Math.abs(month);
        }
        let monthDate = RcTime.time().month(yearNumber, month);
        let firstDay = monthDate.firstDay().firstHour();
        let lastDay = monthDate.lastDay().lastHour();
        time = firstDay.time() + ":" + lastDay.time();
      } else {
        time = month;
      }
    }

    let sortByField = "createdDateZa";
    if (this.isAdminDisplay()) {
      sortByField = "numberZa";
    }

    var filter = new InvoiceListFilter();
    filter
      .withPagination(this.pageSize(query, "invoice"), query["page"] ? (query["page"] - 1) : 0)
      .withDateRange(time)
      .withCompany(this._companyId)
      .withSortBy(query["sortBy"] ? query["sortBy"] : sortByField)
      .done();

    let searchText = query["search"] ? query["search"] : "";
    if (StringUtils.isNotEmpty(searchText)) {
      filter.withSearchText(searchText);
    }

    if (this.isAdmin()) {
      var charge = query["chargeStatus"];
      if (StringUtils.isNotEmpty(charge)) {
        filter.withChargeStatus(charge);
      }

      var autoCharge = query["autoCharge"];
      if (StringUtils.isNotEmpty(autoCharge)) {
        filter.withAutoCharge(autoCharge);
      }

      var autoEmail = query["autoEmail"];
      if (StringUtils.isNotEmpty(autoEmail)) {
        filter.withAutoEmail(autoEmail);
      }

      var promo = query["promo"];
      if (StringUtils.isNotEmpty(promo)) {
        filter.withPromoCode(promo);
      }

      var amount = query["amount"] ? query["amount"] : "gtZero";
      if (StringUtils.isNotEmpty(amount)) {
        filter.withTotalRange(amount);
      }
    }
    return filter;
  }

  loadItems(forceUpdate = true) {
    super.loadItems();
    this.panel().displayProgress("Loading Items");

    var filter = this.createFilter();
    var context = {};
    context["model"] = this;
    context["startTime"] = new Date();
    context["pageName"] = "invoice";
    context["fetched"] = true;
    context["filter"] = JSON.stringify(filter);
    let filterKey = context["filter"];
    let subdomain = this.domain().invoices();

    if (!subdomain.hasFilter(filterKey) || forceUpdate) {
      var event = this.domain().events().invoice().list(filter);
      event.send(this.loadedItemsListener, context);

    } else {
      context["fetched"] = false;
      let data = subdomain.getFilter(filterKey);
      let size = subdomain.getFilterSize(filterKey);
      let response = new RcResponseEvent(this.domain(), {});
      let payload = response.payload();
      payload.withCount(size);
      payload.put(InvoiceMap.MODEL_NAME, data);
      response.withPayload(payload);
      this.loadedItemsListener(response, context);
    }
  }

  loadedItemsListener(responseEvent, context) {
    var model = context.model;
    context["loadTime"] = new Date();
    if (responseEvent.error()) {
      model.withError(responseEvent.error());
    } else {
      var payload = responseEvent.payload();
      model._total = payload.count();
      model.log().info("Items fetched {0}", [model._total]);
      var values = payload.valuesFor(InvoiceMap.MODEL_NAME);
      let map = new InvoiceMap(model.domain(), values);
      if (!context["fetched"]) {
        map = map.find();
      }
      model._invoiceMap = map;
      model._itemList = model._invoiceMap.sort();
      context["sortTime"] = new Date();
      model.domain().invoices().putFilter(context["filter"], map, payload.count());
    }
    var query = model.panel().$route.query;
    model._pages = ContentUtils.PAGE_INDEXES(model._total, model.pageSize(query, "invoice"))
    model
      .withStateContentLoaded()
      .clearAndBuildTableData()
      .done();
    model.panel().showDialogIfRequired();

    delete context.model;
    context["renderTime"] = new Date();
    model.log().info("Client Performance {0}", [JSON.stringify(context, null, 2)]);
  }

  invoices() {
    return this._itemList;
  }

  totalItems() {
    return this._total;
  }

  pages() {
    return this._pages;
  }

  error() {
    return this._error;
  }

}