<template>
  <Row>
    <Column :width="15" :key="redraw">
      <div id="detailsModal" class="rc-modal-dialog">
        <!-- Modal content -->
        <div class="rc-modal-content-dialog-small">
          <div class="rc-modal-header-dialog">
            <Box >
              <Row 
                v-for="(row, rowIndex) in viewHeaderData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withFieldValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>

          <div class="rc-modal-body-dialog">
            <div class="rc-modal-body-inner-dialog rc-border-thin">
              <Box>
                <Row 
                  v-for="(row, rowIndex) in viewBodyData" 
                  :key="rowIndex" >
                    <CellFactory 
                      :rowData="row.data"
                      :showMissingRequirements="showMissingRequirements"
                      v-on:withValue="withFieldValue"
                      v-on:withButtonPressed="withButtonPressed"
                      />
                </Row>
              </Box>
            </div>
          </div>

          <div class="rc-modal-footer-dialog">
            <Box>
              <Row 
                v-for="(row, rowIndex) in viewFooterData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withFieldValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>
        </div>
      </div>
    </Column>
  </Row>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import CellFactory from "@/portals/shared/cell/CellFactory.vue";

import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow";
import EmailImageDisplayRow from "@/portals/shared/cell/image/email/EmailImageDisplayRow";
import SpacerDisplayRow from '@/portals/shared/cell/spacer/SpacerDisplayRow';
import ErrorDisplayRow from "@/portals/shared/cell/error/ErrorDisplayRow";
import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";

export default {
  name: "invoice-paid-thankyou-email-display-view",
  components: {
    Column, Row, 
    Box,
    CellFactory,
  },
  props: {
    invoiceId: { type: String, default: "" },
  },
  data() {
    return {
      redraw: 1,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,

      showMissingRequirements: false,
      viewHeaderData: [],
      viewBodyData: [],
      viewFooterData: [],

      kvps: null,
      error: null,
      StringUpdateRow: StringUpdateRow
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.setup();
    },

    setup: function() {
      window.onclick = this.onClick;

      this.viewHeaderData = [];
      this.viewBodyData = [];
      this.viewFooterData = [];
      var id = ContentUtils.baseDecode(this.invoiceId);
      var invoice = this.domain.invoices().findById(id);
      var company = invoice.company().find();
      var invoiceSettings = company.invoiceSettings().find();

      this.viewHeaderData.push(new TitleDisplayRow().withTitle("Send Thank-you for your Payment Email"));

      if (this.error) {
        var box = new BoxDisplayRow().withBorder(true);
        box
          .withChild(new RowDisplayRow()
            .withChild(new SpacerDisplayRow().withWidth("1"))
            .withChild(new ErrorDisplayRow().withWidth("13").withError(this.error))
            .withChild(new SpacerDisplayRow().withWidth("1"))
          );
        this.viewBodyData.push(box);
      }

      this.viewBodyData.push(new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new EmailImageDisplayRow())
                                    .withChild(new StringDisplayRow().withValue("This will send a thank-you email to"))
                                    .withChild(new SpacerDisplayRow())
                                    .withChild(new LabelValueDisplayRow().withLabel("Company").withValue(company.name()))
                                    .withChild(new LabelValueDisplayRow().withLabel("Billing Company").withValue(invoiceSettings.companyName()))
                                    .withChild(new LabelValueDisplayRow().withLabel("Contact Name").withValue(invoiceSettings.contactName()))
                                    .withChild(new LabelValueDisplayRow().withLabel("Contact Email").withValue(invoiceSettings.contactEmail()))
                                    .withChild(new LabelValueDisplayRow().withLabel("Notification Emails").withValue(invoiceSettings.emailTo()))
                                    .withChild(new SpacerDisplayRow())
                                    .withChild(new SpacerDisplayRow())
                                    .withChild(new StringDisplayRow()
                                                  .withValue("Please note that it will take a for the email to be sent."))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            )
                          );

      this.viewFooterData.push(new BoxDisplayRow()
                  .withBorder(true)
                  .withChild(
                    new RowDisplayRow()
                      .withChild(new ButtonDisplayRow().withWidth("3").withName("Cancel").withField("DetailsClose"))
                      .withChild(new StringDisplayRow().withWidth("9"))
                      .withChild(new ButtonDisplayRow().withWidth("3").withName("Send").withField("Send").withBackgroundPrimaryAction())
                  )
              );
    },

    sendingScreen() {
      this.viewBodyData = [];
      this.viewBodyData.push(new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new EmailImageDisplayRow())
                                    .withChild(new StringDisplayRow()
                                                  .withMandatoryValue(true)
                                                  .withValue("Sending, this may take a moment"))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            )
                          )
      this.viewFooterData = [];
      this.paint();
    },

    doneScreen() {
      this.viewBodyData = [];
      this.viewBodyData.push(new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new EmailImageDisplayRow())
                                    .withChild(new StringDisplayRow()
                                                  .withMandatoryValue(true)
                                                  .withValue("Done!"))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            )
                          )
      this.viewFooterData = [];
      this.paint();
    },

    paint: function() {
      this.redraw++;
    },

    onClick: function(event) {
      var modal = document.getElementById("detailsModal");
      if (event.target == modal) {
        this.goBack();
      }
    },
    
    withButtonPressed: function(buttonData) {
      this.showMissingRequirements = false;
      if (buttonData["field"] === "DetailsClose" || buttonData["field"] === "Close") {
        this.goBack();
      }
      if (buttonData["field"] === "Send") {
        this.sendInvoiceThankyouEmail();
      }
    },

    withFieldValue: function(valueData) {
      if (valueData) {
        //
      }
      this.showMissingRequirements = false;
    },

    displayProgress: function(msg) {
      this.callback(msg);
    },

    callback: function(msg) {
      if (msg) {
        //
      }
    },

    areRequirementsMet: function() {
      var met = true;
      return met;
    },

    sendInvoiceThankyouEmail: function() {
      this.showMissingRequirements = false;
      if (!this.areRequirementsMet()) {
        this.showMissingRequirements = true;
        return false;
      }
      var context = {}
      context["view"] = this;
      var id = ContentUtils.baseDecode(this.invoiceId);
      var invoice = this.domain.invoices().findById(id);
      this.sendingScreen();
      var event = this.domain.events().invoice().emailThanks(invoice);
      event.send(this.sentInvoiceThankyouEmail, context);
      return true;
    },
    
    sentInvoiceThankyouEmail: function(notification) {
      if (notification.error()) {
        this.error = notification.error();
        this.setup();
        this.paint();
      } else {
        this.doneScreen();
        setTimeout(this.goBack, 200);
      }
    },

    goBack() {
      this.$router.back();
    }
  },
}
</script>