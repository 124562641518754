<template>
  <EmailDisplayView :invoiceId="invoiceId" @click="withButtonPressed" @withButtonPressed="withButtonPressed" @withValue="withValue"></EmailDisplayView>
</template>

<script>
import EmailDisplayView from './InvoiceReadyEmailDisplayView.vue';
export default {
  name: 'invoice-ready-email-display-row',
  components: {
    EmailDisplayView
  },
  props: {
    invoiceId: { type: String, default: "" },
  },
  methods: {
    withValue: function(event) {
      this.$emit('withValue', event);
    },
    withButtonPressed: function(event) {
      this.$emit('withButtonPressed', event);
    },
  }
}
</script>
