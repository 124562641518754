<template>
  <Box>
    <Row v-if="isAdmin">
      <Column :width="15">
        <div class="rc-text-centre">
        Admin View - Invoices
        </div>
      </Column>
    </Row>
    
    <DisplayRow v-if="showDialog === 'detailsDialog'" :companyId="selectedId" @click="withButtonPressed" @withButtonPressed="withButtonPressed" @withValue="withValue"></DisplayRow>

    <Row :key="redraw">
      <Column>
        <ListView v-if="isReady" 
          :adminDisplay="true" 
          :isAdmin="true"
          v-on:withValue="withFieldValue"
          v-on:withButtonPressed="withButtonPressed"
          ></ListView>
      </Column>
    </Row>

  </Box>
</template>
<script>
import ListView from '../../../../customer/operator/views/invoice/list/ListView.vue';
import { mapActions, mapGetters } from 'vuex';
  
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
  
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
  
import CellFactory from "@/portals/shared/cell/CellFactory.vue";

import Company from "@/domain/model/company/Company";
import DisplayRow from '../../shared/details/DisplayRow.vue';

export default {
  name: "portals-admin-views-inspection-list-view",
  components: {
    ListView,
    // CellFactory,
    Box, Row, Column,
    DisplayRow,
  },
  props: {
    isAdmin: {type: Boolean, default: true},
  },
  data() {
    return {
      redraw: 1,
      isReady: false,
      error: null,

      MC: MC,
      SU: StringUtils,
      CU: ContentUtils,
      CST: ConstUtils,
      CF: CellFactory,
      
      Box: Box,
      Row: Row,
      Column: Column,

      selectedId: null,
      showDialog: null,
    };
  },
  computed: {
    ...mapGetters([
                    'auth_connected',
                    'auth_socket_status',
                    'signin_event',
                    'auth_client',
                    'domain',
                    ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    },
    $route (to, from) {
        if (to && from) {
          if (to.query["action"] === "details") {
            this.showDialog = "detailsDialog";
            this.selectedId = to.query["selectedId"];

          } else {
            let refresh = false;

            var toQuery = Object.assign({}, to.query);
            var fromQuery = Object.assign({}, from.query);
            
            delete toQuery.action;
            delete toQuery.selectedId;

            delete fromQuery.action;
            delete fromQuery.selectedId;

            if (JSON.stringify(toQuery, null, 0) !== JSON.stringify(fromQuery, null, 0)) {
              refresh = true;
            }

            if (StringUtils.isNotEmpty(this.showDialog)) {
              this.showDialog = ""; 
            } 

            if (refresh) {
              this.reloadPage();
            }
          }
        }
      }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                    'AuthStorage_updateCompany',
                    'addListenerAction',
                    'removeListenerAction',
                    'updateDomain',
                    ]),

    start: function() {
      let company = new Company(this.domain).withName("Administrator Portal");
      let session = this.domain.session();
      session.with(company);
      session.companyName = "Administrator Portal"
      this.AuthStorage_updateCompany(company);
      this.isReady = true;
    },

    displayProgress: function(msg) {
      this.callback(msg);
    },
    
    callback: function(msg) {
      var row = null;
      if (row && msg) {
        this.paint();
      }
    },
    
    log: function() {
      return this.domain.logger();
    },

    paint: function() {
      this.redraw++;
    },

    deleteQuery(query) {
      delete query.action;
      delete query.selectedId;
      return query;
    },
    
    showDetailsIfRequired: function() {
      var query = Object.assign({}, this.$route.query);
      if (StringUtils.isNotEmpty(query["action"])) {
        this.selectedId = query["selectedId"];
        this.showDialog = query["action"];
      } 
    },

    reloadPage: function() {
      this.paint()
    },

    withFieldValue: function(valueData) {
      if (valueData == null) {
        return;
      }
      this.log().info("Admin {0}", [JSON.stringify(valueData, null, 2)]);
    },

    withButtonPressed: function(buttonData) {
      if (buttonData == null) {
        return;
      }

      if (buttonData["field"] === "Select") {
        let query = Object.assign({}, this.$route.query);
        query["action"] = "details";
        query["selectedId"] = ContentUtils.baseEncode(buttonData["id"]);
        this.$router.push({ query });
      }

      this.log().info("Admin {0}", [JSON.stringify(buttonData, null, 2)]);
    },

    chargedDetailsListener(notification, context) {
      let view = context.view;
      if (notification.error()) {
        view.error = notification.error();
        view.paint();
      } else {
        view.reloadPage();
      }
    }
  }
}
</script>