import RcTableViewUtils from "../../../../../shared/table/RcTableViewUtils";

import LabelDisplayRow from "@/portals/shared/cell//label/LabelDisplayRow";
import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";

import MC from "@/domain/session/MC.js";
import RcTime from "@/domain/session/time/RcTime.js";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import InvoiceRow from "../../../../../shared/cell/invoice/InvoiceRow";

// import Company from "@/domain/model/company/Company.js";

export default class ListViewUtils extends RcTableViewUtils {

  static MONTHS(year) {
    return [
      { '@rid': 'all', 'name': 'All' },
      { '@rid': 'thisMonth', 'name': 'This Month' },
      { '@rid': 'lastMonth', 'name': 'Last Month' },
      { '@rid': 'last60Days', 'name': 'Last 60 Days' },
      { '@rid': '-4', 'name': 'May ' + (year - 1) },
      { '@rid': '-5', 'name': 'Jun ' + (year - 1) },
      { '@rid': '-6', 'name': 'Jul ' + (year - 1) },
      { '@rid': '-7', 'name': 'Aug ' + (year - 1) },
      { '@rid': '-8', 'name': 'Sep ' + (year - 1) },
      { '@rid': '-9', 'name': 'Oct ' + (year - 1) },
      { '@rid': '-10', 'name': 'Nov ' + (year - 1) },
      { '@rid': '-11', 'name': 'Dec ' + (year - 1) },
      { '@rid': '0', 'name': 'Jan ' + year },
      { '@rid': '1', 'name': 'Feb ' + year },
      { '@rid': '2', 'name': 'Mar ' + year },
      { '@rid': '3', 'name': 'Apr ' + year },
      { '@rid': '4', 'name': 'May ' + year },
      { '@rid': '5', 'name': 'Jun ' + year },
      { '@rid': '6', 'name': 'Jul ' + year },
      { '@rid': '7', 'name': 'Aug ' + year },
      { '@rid': '8', 'name': 'Sep ' + year },
      { '@rid': '9', 'name': 'Oct ' + year },
      { '@rid': '10', 'name': 'Nov ' + year },
      { '@rid': '11', 'name': 'Dec ' + year },
    ];
  }

  static SORT_FIELDS() {
    return [
      { '@rid': 'numberAz', 'name': 'Invoice # (A-Z)' },
      { '@rid': 'numberZa', 'name': 'Invoice # (Z-A)' },
      { '@rid': 'createdDateAz', 'name': 'Created Date (Old->New)' },
      { '@rid': 'createdDateZa', 'name': 'Created Date (New->Old)' },
    ];
  }

  static AMOUNTS() {
    return [
      { '@rid': 'all', 'name': 'All' },
      { '@rid': 'gtZero', 'name': '> 0' },
    ];
  }

  static CHARGE_STATUS() {
    return [
      { '@rid': 'all', 'name': 'All' },
      { '@rid': 'captured', 'name': 'Captured' },
      { '@rid': 'declined', 'name': 'Declined' },
      { '@rid': 'empty', 'name': 'Empty' },
    ];
  }

  static PROMOS() {
    return [
      { '@rid': 'all', 'name': 'All' },
      { '@rid': 'notempty', 'name': 'Not Empty' },
      { '@rid': 'TRI30', 'name': 'TRI 30' },
      { '@rid': 'DV30', 'name': 'DV 30' },
      { '@rid': 'SD30', 'name': 'SD 30' },
      { '@rid': 'ECC30', 'name': 'ECC 30' },
      { '@rid': 'KCI30', 'name': 'KCI 30' },
      { '@rid': 'WTS30', 'name': 'WTS 30' },
    ];
  }

  static CHARGE() {
    return [
      { '@rid': 'all', 'name': 'All'},
      { '@rid': 'true', 'name': 'Auto Charging'},
      { '@rid': 'false', 'name': 'Not Auto Charging'},
    ];
  }

  static EMAIL() {
    return [
      { '@rid': 'all', 'name': 'All'},
      { '@rid': 'true', 'name': 'Auto Emailing'},
      { '@rid': 'false', 'name': 'Not Auto Emailing'},
      { '@rid': 'empty', 'name': 'Empty'},
    ];
  }

  static PAID() {
    return [
      { '@rid': 'all', 'name': 'All'},
      { '@rid': 'true', 'name': 'Yes'},
      { '@rid': 'false', 'name': 'No'},
    ];
  }

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this.pages = [];
    this.Strings = new StringUtils();
  }

  start() {
    if (this.model().hasError()) {
      this.renderError();
    }

    if (this.model().isStatePageLoading()) {
      super.start();
      return;
    }

    this.renderHeader(this.model().totalItems());

    if (this.model().isStateContentLoading()) {
      super.start();
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderBody();
    }

    this.renderFooter(this.model().totalItems());
  }

  renderBody() {
    let list = this.model().invoices();
    let even = true;
    for (let index = 0; index < list.length; index++) {
      let invoice = list[index];
      let invoiceRow = new InvoiceRow();
      invoiceRow
        .withInvoice(invoice)
        .withIsAdmin(this.model().isAdmin())
        .withEven(even);
      this.addBody(invoiceRow);
      even = !even;
    }
  }

  renderFooter(totalItems) {
    let query = this.model().panel().$route.query;
    this.pages = this.model().pages();
    this.addFooter(
      new BoxDisplayRow()
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabel("Showing").withValue(this.createPageInfo(totalItems, query, "invoice")))
              .withChild(new StringDisplayRow().withWidth("7"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                .withShowLabel(false)
                .withName("Jump to Page")
                .withField("PageIndex")
                .withDefaultValue(query["page"] ? query["page"] : "1")
                .withOptions(this.pages))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
    );
  }

  renderHeader(totalItems) {
    let query = this.model().panel().$route.query;
    let filters = null;
    let now = RcTime.time().now();
    let month = query["month"] ? query["month"] : now.month().previous().value() + "";
    if (!this.model().isAdminDisplay() && !query["month"]) {
      month = "all";
    }
    if (this.model().isAdmin()) {
      filters = new BoxDisplayRow().withBorder(true)
        .withChild(new LabelDisplayRow().withLabel("Filters"))
        .withChild(new RowDisplayRow()
          .withChild(new StringListUpdateRow().withWidth(2)
            .withShowLabel(true)
            .withShowClear(true)
            .withName("Month")
            .withField("Month")
            .withDefaultValue(month)
            .withOptions(ListViewUtils.MONTHS(now.year().value())))
          .withChild(new StringListUpdateRow()
            .withShowLabel(true)
            .withField("PromoCode")
            .withName("Promo Codes")
            .withWidth(2)
            .withOptions(ListViewUtils.PROMOS())
            .withKey("promo")
            .withDefaultValue(query["promo"] ? query["promo"] : "all")
            .withShowClear(true)
          )
          .withChild(new StringListUpdateRow()
            .withShowLabel(true)
            .withField("Amount")
            .withName("Amount")
            .withWidth(2)
            .withOptions(ListViewUtils.AMOUNTS())
            .withKey("amount")
            .withDefaultValue(query["amount"] ? query["amount"] : "gtZero")
            .withShowClear(true)
          )
          .withChild(new StringListUpdateRow()
            .withShowLabel(true)
            .withField("chargeStatus")
            .withName("Charge Status")
            .withWidth(2)
            .withOptions(ListViewUtils.CHARGE_STATUS())
            .withKey("chargeStatus")
            .withDefaultValue(query["chargeStatus"] ? query["chargeStatus"] : "all")
            .withShowClear(true)
          )
          .withChild(new StringListUpdateRow()
                                      .withShowLabel(true)
                                      .withField("autoCharge")
                                      .withName("Auto Charge")
                                      .withWidth(2)
                                      .withOptions(ListViewUtils.CHARGE())
                                      .withKey("autoCharge")
                                      .withDefaultValue(query["autoCharge"] ? query["autoCharge"] : "all")
                                      .withShowClear(true)
                                    )
          .withChild(new StringListUpdateRow()
                      .withShowLabel(true)
                      .withField("autoEmail")
                      .withName("Email Invoice")
                      .withWidth(2)
                      .withOptions(ListViewUtils.EMAIL())
                      .withKey("autoEmail")
                      .withDefaultValue(query["autoEmail"] ? query["autoEmail"] : "all")
                      .withShowClear(true)
                    )
        );
    }

    let searchRow = new RowDisplayRow()
      .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Search"))
      .withChild(new StringUpdateRow().withWidth("5")
        .withShowLabel(false).withField("Search")
        .withKey("Search").withName("Search")
        .withDefaultValue(query["search"] ? query["search"] : ""))
      .withChild(new ButtonDisplayRow().withWidth("1").withName("Go").withKey("Search").withField("Search").withSelected(false))
      .withChild(new ButtonDisplayRow().withWidth("1").withName("Clear").withKey("Search").withField("Clear").withSelected(false))
      .withChild(new SpacerDisplayRow().withWidth("7"));
    if (this.model().isAdmin() && searchRow) {
      filters.addChild(searchRow);
    }
    let company = this.model().company();
    let settings = company.invoiceSettings();
    let balance = new LabelValueDisplayRow()
              .withLabel("Overpayment Balance")
              .withValue(StringUtils.displayAsMoney(settings.overPaymentBalance()))
              .withWidth(4)
              .withLabelWidth(8)
              .withKey("balance")
              ;
    let exportData = new SpacerDisplayRow().withWidth(2);
    if (this.model().isAdminDisplay()) {
      balance = new SpacerDisplayRow().withWidth(4);
      exportData = new ButtonDisplayRow().withWidth(2).withName("Export").withField("Export");
    }

    let sortByLabel = new SpacerDisplayRow().withWidth(1);
    let sortBy = new SpacerDisplayRow().withWidth(2);
    if (this.model().isAdminDisplay()) {
      sortByLabel = new LabelDisplayRow().withWidth("1").withLabel("Sort By");
      sortBy = new StringListUpdateRow().withWidth("2")
                    .withName("Sort By")
                    .withField("SortBy")
                    .withShowLabel(false)
                    .withDefaultValue(query["sortBy"] ? query["sortBy"] : "numberZa")
                    .withOptions(ListViewUtils.SORT_FIELDS());
    }
    this.addHeader(
      new RowDisplayRow()
        .withChild(
          new BoxDisplayRow()
            .withWidth("15")
            .withChild(new RowDisplayRow()
              .withChild(new LabelDisplayRow().withLabel("Invoices").withWidth(7))
              .withChild(balance)
              .withChild(exportData)
              .withChild(new ButtonDisplayRow().withWidth(2).withName("Refresh").withField("Refresh"))
            )
            .withChild(filters)

            .withChild(new RowDisplayRow()
              .withBorder(true)
              .withChild(new BoxDisplayRow()
                .withWidth("15")
                .withChild(new RowDisplayRow()
                  .withChild(new LabelValueDisplayRow()
                    .withWidth("4").withLabelWidth("5")
                    .withLabel("Showing")
                    .withValue(this.createPageInfo(totalItems, query, "invoice")))
                  .withChild(new SpacerDisplayRow().withWidth(1))
                  .withChild(sortByLabel)
                  .withChild(sortBy)
                  .withChild(new StringListUpdateRow().withWidth("3")
                    .withName("Items/Page")
                    .withField("PageSize")
                    .withShowLabel(false)
                    .withDefaultValue(this.model().pageSize(query, "invoice"))
                    .withOptions(ContentUtils.PAGE_SIZES()))
                  .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
                  .withChild(new StringListUpdateRow().withWidth("2")
                    .withShowLabel(false)
                    .withName("Jump to Page")
                    .withField("PageIndex")
                    .withDefaultValue(query["page"] ? query["page"] : "1")
                    .withOptions(ContentUtils.PAGE_INDEXES(totalItems, this.model().pageSize(query, "invoice"))))
                  .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
                )
              )
            )
        )
    );

    let box = new BoxDisplayRow()
      .withBorder(true);
    box
      .withChild(new RowDisplayRow()
        .withBackgroundTitle()
        .withChild(new TitleDisplayRow().withAlign("centre").withWidth("2").withTitle("Invoice #"))
        .withChild(new TitleDisplayRow().withAlign("centre").withWidth("2").withTitle("Issued On"))
        .withChild(new TitleDisplayRow().withAlign("centre").withWidth("2").withTitle("PreTax Total"))
        .withChild(new TitleDisplayRow().withAlign("centre").withWidth("2").withTitle("Total"))
        .withChild(new TitleDisplayRow().withAlign("centre").withWidth("2").withTitle("Payments"))
        .withChild(new TitleDisplayRow().withAlign("centre").withWidth("2").withTitle("Remaining"))
        .withChild(new TitleDisplayRow().withAlign("centre").withWidth("2").withTitle("Due Date"))
        .withChild(new TitleDisplayRow().withAlign("centre").withWidth("1").withTitle("Paid"))
      );
    
    this.addHeader(box);
  }

  makeAndModel(vehicle) {
    let value = "";
    let makeSet = false;
    if (StringUtils.isNotEmpty(vehicle.make())) {
      value = vehicle.make();
      makeSet = true;
    }
    if (StringUtils.isNotEmpty(vehicle.model())) {
      if (makeSet) {
        value = value + " / " + vehicle.model();
      } else {
        value = " " + "/ " + vehicle.model();
      }
    } else {
      if (makeSet) {
        value = value + " / " + ""
      }
    }
    return value;
  }

  categoryName(vehicle) {
    let category = vehicle.category().find();
    let list = ContentUtils.CATEGORIES();
    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      if (item['@rid'] == category.id()) {
        return item['name'];
      }
    }
    return category.name();
  }

  chargeStatus(invoice, invoiceSettings) {
    if (invoiceSettings) {
      //
    }
    if (invoice.hasChargeStatus()) {
      if (invoice.isChargeStatusGood()) {
        return "Captured"
      }
      if (invoice.isChargeStatusBad()) {
        return "Failed"
      }
    }
    return "Blank";
  }

}